import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { BiChevronLeft } from "react-icons/bi"
import { IconContext } from "react-icons"
import theme from "../../styles/theme"
import { buttonLinkStyles } from "./styles"

const ChevronLeftIconComponent = () => (
  <IconContext.Provider
    value={{ color: `${theme.colors.primary.brown}`, size: "1.62em" }}
  >
    <BiChevronLeft />
  </IconContext.Provider>
)

const StyledButton = styled.button`
  ${buttonLinkStyles}
  background: #fff;
  color: ${() => theme.colors.primary.brown};
  border: 2px solid ${({ theme }) => theme.colors.primary.brown};
  transition: 0.6s;
  overflow: hidden;
  svg {
    margin-left: 0.32em;
    transition: 0.6s;
  }
  &:hover {
    background-color: transparent;
    border: none;
    svg {
      fill: #fff;
      transform: translateX(-30px);
    }
  }
`

export default function HistoryBackLink({ text = "もどる" }) {
  return (
    <StyledButton onClick={() => navigate(-1)}>
      <ChevronLeftIconComponent />
      {text}
    </StyledButton>
  )
}
