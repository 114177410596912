import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { IconContext } from "react-icons"
import { BiChevronLeft } from "react-icons/bi"
import { media } from "../../styles/media"
import theme from "../../styles/theme"
import { buttonLinkStyles } from "./styles"

const StyledLink = styled(Link)`
  ${buttonLinkStyles}
  background: #fff;
  color: ${() => theme.colors.primary.brown};
  border: 2px solid ${({ theme }) => theme.colors.primary.brown};
  transition: 0.6s;
  overflow: hidden;
  height: 36.5px;
  svg {
    margin-left: 0.32em;
    transition: 0.6s;
  }
  &:hover {
    background-color: transparent;
    border-color: transparent;
    svg {
      fill: #fff;
      transform: translateX(-50px);
    }
  }
  /* ${() =>
    media.sp(css`
      margin-bottom: 10px;
    `)} */
`

export default function BackLinkButton({
  to = "",
  text = "",
  color = `${theme.colors.primary.brown}`,
}) {
  return (
    <StyledLink to={to}>
      <IconContext.Provider value={{ color }}>
        <BiChevronLeft />
      </IconContext.Provider>
      {text}
    </StyledLink>
  )
}
