import { css } from "styled-components"

const baseLinkStyles = css`
  font-size: 0.9em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`

export const buttonLinkStyles = css`
  ${baseLinkStyles}
  border-radius: 5px;
  padding: 0.32em 1.62em;
`

export const linkTextStyles = props => css`
  /* display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center; */
  ${baseLinkStyles}
  line-height: 1rem;
  text-decoration: none;
  color: #c30f23;
  transition: 0.3s;
  svg {
    transition: 0.3s;
  }
  :hover {
    opacity: 0.8;
    color: #e50012;
    svg {
      stroke: #e50012;
    }
  }
  :not(:first-of-type) span {
    margin-inline-end: 8px;
  }
  ${props}
`
