import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { AiOutlineSwapRight } from "react-icons/ai"
import { IconContext } from "react-icons"
import { media } from "../../styles/media"
import { buttonLinkStyles } from "./styles"

const StyledLink = styled(Link)`
  ${buttonLinkStyles}
  background: ${({ theme }) => theme.colors.primary.brown};
  color: white;
  border: 2px solid ${({ theme }) => theme.colors.primary.brown};
  transition: 0.6s;
  overflow: hidden;
  svg {
    margin-left: 0.32em;
    transition: 0.6s;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary.brown};
    background: white;
    svg {
      fill: ${({ theme }) => theme.colors.primary.brown};
      transform: translateX(50px);
    }
  }
`

const SwapRightIcon = () => (
  <IconContext.Provider value={{ color: "#fff" }}>
    <AiOutlineSwapRight />
  </IconContext.Provider>
)
export default function LinkButton({
  to = "",
  text = "",
  iconComponent = <SwapRightIcon />,
}) {
  return (
    <StyledLink to={to}>
      {text}
      {iconComponent}
    </StyledLink>
  )
}
