import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"

import { media } from "../styles/media"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import HeaderVisual from "../components/HeaderVisual"
import { LinkTextWithArrow } from "../components/Link"

const ContentsWrap = styled.div(
  ({ theme }) => css`
    padding: 50px 5% 100px;
    max-width: 800px;
    margin: 0 auto;
    h3 {
      font-size: 1.32em;
      line-height: 1.62em;
      /* color:${theme.colors.primary.brown}; */
    }
  `
)

const TextPara = styled.div`
  padding: 30px 0;
  p {
    padding: 0.62em 0;
  }
`
const LinkSec = styled.div`
  width: 90%;
  margin: 30px auto 30px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  /* a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: 600;
    font-size: 0.9em;
    transition: 0.8s;
    border-bottom: 1px solid transparent;
    svg {
      transition: 0.6s;
      animation: armove 3s ease 0s infinite alternate;
    }
    &:hover {
      border-bottom: 1px solid red;
      svg {
        transform: translateX(5px);
        opacity: 0;
      }
    }
  }
  @keyframes armove {
    0% {
      transform: translateX(0px);
    }
    20% {
      transform: translateX(0px);
    }
    25% {
      transform: translateX(8px);
    }
    30% {
      transform: translateX(0px);
    }
    35% {
      transform: translateX(4px);
    }
    40% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(0px);
    }
  } */
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      row-gap: 1rem;
      margin-block: 0;
      width: 300px;
      /* margin: 0 auto; */
      /* a {
        margin: 10px 0;
      } */
    `)}
`

export default function OurPolicy() {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <SEO>
        <title>おいしいパンを求めて｜フランソア</title>
        <meta name="description" content="私たちの体は食べもので作られて..." />
      </SEO>

      <HeaderVisual
        backImage={
          data.strapiOurpolicyCommitment.titleMainVisual.childImageSharp.fluid
        }
        mainTitle={data.strapiOurpolicyCommitment.pageTitle}
        mainText={data.strapiOurpolicyCommitment.textUnderTitle}
      />

      <ContentsWrap>
        <h3>{data.strapiOurpolicyCommitment.bodyTitle}</h3>
        {data.strapiOurpolicyCommitment.longTextSection.map(
          ({ longTextParagraphs }, index) => (
            <TextPara
              key={index}
              dangerouslySetInnerHTML={{ __html: longTextParagraphs }}
            />
          )
        )}
        <LinkSec>
          {data.strapiOurpolicyCommitment.pageLinkNavigation.map(
            ({ linkButtonUrl, linkButtonName }) => {
              if (String(linkButtonUrl).startsWith("/")) {
                return (
                  <LinkTextWithArrow
                    key={linkButtonUrl}
                    href={linkButtonUrl}
                    text={linkButtonName}
                  />
                )
              }
              return (
                <LinkTextWithArrow
                  key={linkButtonUrl}
                  href={linkButtonUrl}
                  text={linkButtonName}
                  isExternal={true}
                />
              )
            }
          )}
        </LinkSec>
      </ContentsWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiOurpolicyCommitment {
      pageTitle
      textUnderTitle
      titleMainVisual {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bodyTitle
      pageLinkNavigation {
        linkButtonName
        linkButtonUrl
        id
      }
      longTextSection {
        id
        longTextParagraphs
      }
    }
  }
`
