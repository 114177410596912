import React from "react"
import styled from "styled-components"
import { AiOutlineFilePdf } from "react-icons/ai"
import { IconContext } from "react-icons/lib"
import { linkTextStyles } from "./styles"

/**
 * A styled external link component.
 * @param {Object} props - The props object.
 * @param {Object} props.styles - The styles object.
 * @returns {JSX.Element} - The JSX element.
 */
const StyledLink = styled.a`
  column-gap: 0.5rem;
  ${props => {
    if (props.styles) {
      return linkTextStyles({ ...props.styles })
    }
    return linkTextStyles()
  }}
`

export default function LinkTextWithPDFDownload({ href, text, styles = {} }) {
  return (
    <StyledLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      styles={styles}
    >
      <span>{text}</span>
      <IconContext.Provider
        value={{ color: "#c30f23", size: "1.4em", strokeWidth: "1px" }}
      >
        <AiOutlineFilePdf />
      </IconContext.Provider>
    </StyledLink>
  )
}
