import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FiChevronRight, FiExternalLink } from "react-icons/fi"
import { IconContext } from "react-icons/lib"
import { linkTextStyles } from "./styles"

/**
 * A styled external link component.
 * @param {Object} props - The props object.
 * @param {Object} props.styles - The styles object.
 * @returns {JSX.Element} - The JSX element.
 */
const StyledExternalLink = styled.a`
  column-gap: 0.5rem;
  ${props => {
    if (props.styles) {
      // return styles({ ...props.styles })
      return linkTextStyles({ ...props.styles })
    }
    // return styles
    return linkTextStyles()
  }}
`

/**
 * A styled link component with an optional styles prop.
 * @param {Object} props - The props object.
 * @param {Object} props.styles - An optional styles object.
 * @returns {JSX.Element} - A styled link component.
 */
const StyledLink = styled(Link)`
  ${props => {
    if (props.styles) {
      // return styles({ ...props.styles })
      return linkTextStyles({ ...props.styles })
    }
    // return styles
    return linkTextStyles
  }}
`

const ChervronRightIconComponent = () => (
  <IconContext.Provider value={{ color: "#c30f23", size: "1.62em" }}>
    <FiChevronRight />
  </IconContext.Provider>
)

export default function LinkTextWithArrow({
  href,
  text,
  isExternal = false,
  styles = {},
  iconComponent = <ChervronRightIconComponent />,
}) {
  if (isExternal) {
    return (
      <StyledExternalLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        styles={styles}
      >
        <span>{text}</span>
        <IconContext.Provider
          value={{ color: "#c30f23", size: "1.4em", strokeWidth: "1px" }}
        >
          <FiExternalLink />
        </IconContext.Provider>
      </StyledExternalLink>
    )
  }
  return (
    <StyledLink to={href} styles={styles}>
      <span>{text}</span>
      {iconComponent}
    </StyledLink>
  )
}
