import React from "react"
import styled, { css } from "styled-components"
import { FiChevronRight } from "react-icons/fi"
import { IconContext } from "react-icons/lib"
import { media } from "../../styles/media"
import { linkTextStyles } from "./styles"

const styles = props => css`
  ${linkTextStyles()}
  /* display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  text-decoration: none;
  font-size: 0.9em;
  color: #c30f23;
  transition: 0.3s;
  svg {
    transition: 0.3s;
  }
  :hover {
    opacity: 0.8;
    color: #e50012;
    svg {
      stroke: #e50012;
    }
  }
  :not(:first-of-type) span {
    margin-inline-end: 8px;
  } */
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      row-gap: 0.75rem;
      svg {
        display: none;
      }
    `)}
  ${props}
`

/**
 * A styled link component with an optional styles prop.
 * @param {Object} props - The props object.
 * @param {Object} props.styles - An optional styles object.
 * @returns {JSX.Element} - A styled link component.
 */
const StyledLink = styled.a`
  ${props => {
    if (props.styles) {
      return styles({ ...props.styles })
    }
    return styles
  }}
`

const StyledSpanWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 4px;
`

export default function WithThumbnailLinkText({
  href,
  text,
  styles = {},
  thumbnailComponent,
}) {
  return (
    <StyledLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      styles={styles}
    >
      {Array.isArray(text) ? (
        <StyledSpanWrapper>
          {text.map(txt => (
            <span style={{ margin: 0, textAlign: "center" }}>{txt}</span>
          ))}
        </StyledSpanWrapper>
      ) : (
        <span style={{ textAlign: "center" }}>{text}</span>
      )}
      <IconContext.Provider value={{ color: "#c30f23", size: "1.62em" }}>
        <FiChevronRight />
      </IconContext.Provider>
      {thumbnailComponent}
    </StyledLink>
  )
}
